import React, { FunctionComponent, useContext, useEffect, useReducer, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  ANNUAL_FEATURES_FIELDS,
  IMAGE_FIELDS,
  RENT_FEATURES_FIELDS,
  SEASONAL_FEATURES_FIELDS,
  SEASONAL_PRICE,
} from "@shared/fragments";
import { generateSearchArticleParamData, generateSearchArticleURL } from "../../utils/search";
import { SearchAccomodationArticles } from "../../models/searchArticles";
import { ArticlesContainer, ArticlesPagination } from "./articles";
import articlesPaginationReducer from "./articlesPagination.reducer";
import { useIntl } from "react-intl";
import { FilterModuleContext } from "../../contexts/filterModule";

const WEEKLY_RENT = gql`
    ${IMAGE_FIELDS}
    ${RENT_FEATURES_FIELDS}
    query GetWeeklyRentSummaries(
        $first:Int,
        $after:String,
        $last:Int,
        $before:String,
        $roomsRange:IntRangeFilterInput,
        $minGuests:Int,
        $price:PriceRangeFilterInput,
        $period:PeriodBookingFilterInput,
        $objectTypes:[String!],
        $parking:Boolean,
        $wifi:Boolean,
        $tv:Boolean,
        $pet:Boolean,
        $fireplace:Boolean,
        $dishwasher:Boolean
        $name:String
        )
        {
            search(
                where:{
                    rentalAccommodation:{
                        roomsRange:$roomsRange,
                        price:$price,
                        period: $period,
                        guests:$minGuests,
                        objectTypes:$objectTypes,
                        parking:$parking,
                        wifi:$wifi,
                        tv:$tv,
                        pet:$pet,
                        fireplace:$fireplace,
                        dishwasher:$dishwasher,
                        name:$name
                    }
                },
                first:$first,
                after:$after,
                last:$last,
                before:$before
                paginationLinksLength : 3
            )
            {
                totalCount
                edges {
                    cursor
                    node {
                        ... on RentalAccommodationSummary {
                            __typename,
                            id,
                            title {value},
                            promoted,
                            novelty,
                            coordinates,
                            priceOnDemand,
                            price {currency , amount},
                            images { ...ImageFields },
                            features { ...RentalFeaturesFields }
                            discounts {
                                definitionType
                                period
                                type
                                percentageValue
                                fixedValue {
                                    currency
                                    amount
                                }
                            }
                        }
                    }
                    bookingSuggestion {
                    checkIn
                    checkOut
                    bookable
                    priceOnDemand
                    price {
                        isDiscounted
                        original
                        amount
                        currency
                    }
                    hasExtras
                    extras {
                        id
                        type
                        amount
                    }
                }
                }
                pageInfo{
                    page
                    pageSize
                    pageCount
                    hasPreviousPage
                    previousCursor
                    hasNextPage
                    nextCursor
                    lastCursor
                    linkCursors { page cursor }
                }
                allMarkers { 
                    id 
                    coordinates
                    priceOnDemand
                    price { amount currency }
                }
            }
        }
`;
const SEASONAL_RENT = gql`
    ${IMAGE_FIELDS}
    ${SEASONAL_FEATURES_FIELDS}
    ${SEASONAL_PRICE}
    query GetSeasonalRentSummaries(
        $first:Int,
        $after:String,
        $last:Int,
        $before:String,
        $roomsRange:IntRangeFilterInput,
        $price:PriceRangeFilterInput,
        $objectTypes:[String!],
        $pet:Boolean,
        $parking:Boolean,
        $fireplace:Boolean,
        $dishwasher:Boolean,
        $wifi:Boolean,
        $tv:Boolean,
        $name:String
        )
        {
            search(
                where:{
                    seasonalAccommodation:{
                        roomsRange:$roomsRange,
                        price:$price,
                        objectTypes:$objectTypes,
                        pet: $pet
                        parking:$parking,
                        fireplace:$fireplace,
                        dishwasher:$dishwasher,
                        tv:$tv,
                        wifi:$wifi,
                        name:$name
                    }
                },
                first:$first,
                after:$after,
                last:$last,
                before:$before
                paginationLinksLength : 3
            )
            {
                totalCount
                edges {
                    cursor
                    node {
                        ... on SeasonalAccommodationSummary {
                            __typename,
                            id,
                            title {value},
                            coordinates,
                            priceOnDemand,
                            price { ...SeasonalPrice},
                            images { ...ImageFields },
                            features { ...SeasonalFeaturesFields }
                        }
                    }
                }
                pageInfo{
                    page
                    pageSize
                    pageCount
                    hasPreviousPage
                    previousCursor
                    hasNextPage
                    nextCursor
                    lastCursor
                    linkCursors { page cursor }
                }
                allMarkers { id coordinates }
            }
        }
`;
const ANNUAL_RENT = gql`
    ${IMAGE_FIELDS}
    ${ANNUAL_FEATURES_FIELDS}
    query GetAnnualRentSummaries(
        $first:Int,
        $after:String,
        $last:Int,
        $before:String,
        $roomsRange:IntRangeFilterInput,
        $surface:Int,
        $price:PriceRangeFilterInput,
        $objectTypes:[String!],
        # $pet:Boolean,
        $parking:Boolean,
        $fireplace:Boolean,
        $dishwasher:Boolean,
        $wifi:Boolean,
        $tv:Boolean,
        $name:String
        )
        {
            search(
                where:{
                    annualAccommodation:{
                        roomsRange:$roomsRange,
                        price:$price,
                        surface:$surface,
                        objectTypes:$objectTypes
                        # pet: $pet
                        parking:$parking,
                        fireplace:$fireplace,
                        dishwasher:$dishwasher,
                        tv:$tv,
                        wifi:$wifi,
                        name:$name
                    }
                },
                first:$first,
                after:$after,
                last:$last,
                before:$before
                paginationLinksLength : 3
            )
            {
                totalCount
                edges {
                    cursor
                    node {
                        ... on AnnualAccommodationSummary {
                            __typename,
                            id,
                            title {value},
                            coordinates,
                            priceOnDemand,
                            price {currency , amount},
                            availableFrom
                            images { ...ImageFields },
                            features { ...AnnualFeaturesFields }
                        }
                    }
                }
                pageInfo{
                    page
                    pageSize
                    pageCount
                    hasPreviousPage
                    previousCursor
                    hasNextPage
                    nextCursor
                    lastCursor
                    linkCursors { page cursor }
                }
                allMarkers { id coordinates }
            }
        }
`;
//RENTAL
const initialSearchRentArticleData = new SearchAccomodationArticles("Rent");

export const WeeklyLocation:FunctionComponent = ()=>{
    const location = useLocation();
    const intl = useIntl();
    const [searchParams,setSearchParams] = useSearchParams();
    const [weeklyRentContent,setWeeklyRentContent] = useState<any|null>(null)
    const [displayable,isDisplayable] = useState<boolean>(false)
    const {searchData, dispatchSearchData} = useContext(FilterModuleContext);
    const [articleSearchData,dispatchArticleSearchData] = useReducer<(articleSearchData: SearchAccomodationArticles, action: any) => any>(articlesPaginationReducer,initialSearchRentArticleData)
    const [additionalFilter, setAdditionalFilter] = useState<string[]>([]);
    const [filterNameValue,setFilterNameValue] = useState<string | undefined>(undefined);


    useEffect(()=>{ //STEP 1 : Create search url if state is present
        if(!!location.state){
            const data = location.state
            const paramArrays = generateSearchArticleParamData(data)
            setSearchParams(generateSearchArticleURL(paramArrays))
        }else{
            dispatchSearchData({
                type: 'InitTypeOfSearch',
                value: "weekly",
            });
        }
    },[])

    useEffect(() => {
        const paramArrays = generateSearchArticleParamData(searchData);
        setSearchParams(generateSearchArticleURL(paramArrays));        
    },[additionalFilter, filterNameValue]);

    useEffect(()=>{ //STEP 2: Dispatch search data changes as soon as URL changes

        dispatchArticleSearchData({
            type: "weekly",
            direction:searchParams.get("dir"),
            id: searchParams.get("page"),
            roomsRange:searchParams.get("roomsRange"),
            from: searchParams.get("from"),
            to:searchParams.get("to"),
            minGuests:searchParams.get("minGuests"),
            objectTypes:searchParams.get("objectTypes"),
            parking:searchParams.get("parking"),
            wifi:searchParams.get("wifi"),
            tv:searchParams.get("tv"),
            pet:searchParams.get("pet"),
            fireplace:searchParams.get("fireplace"),
            dishwasher:searchParams.get("dishwasher"),
        });
    },[searchParams])

    const { error, loading, refetch } = useQuery(WEEKLY_RENT, { //STEP 3 : Data fetching
        variables:{
            first:articleSearchData.first,
            after:articleSearchData.after,
            last:articleSearchData.last,
            before:articleSearchData.before,
            roomsRange:articleSearchData.qRent.roomsRange,
            minGuests:articleSearchData.qRent.minGuests,
            period:articleSearchData.qRent.period,
            objectTypes:articleSearchData.qRent.objectTypes,
            parking: articleSearchData.qRent.parking? true : additionalFilter.includes("parking")? true : null,
            wifi: articleSearchData.qRent.wifi? true : additionalFilter.includes("wifi")? true : null,
            tv: articleSearchData.qRent.tv? true : additionalFilter.includes("tv")? true : null,
            pet: articleSearchData.qRent.pet? true : additionalFilter.includes("pet")? true : null,
            fireplace: articleSearchData.qRent.fireplace? true : additionalFilter.includes("fireplace")? true : null,
            dishwasher: articleSearchData.qRent.dishwasher? true : additionalFilter.includes("dishwasher")? true : null,
            name: searchParams.get("name")
        },
        ssr: true,
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted:(data)=>{
            setWeeklyRentContent(data.search)
            isDisplayable(true)
        },
    });

    useEffect(()=>{ //STEP 4: Refetch datas as soon as query change
        refetch()
    },[articleSearchData])

    if(error){
        return(
            <>
                <ArticlesContainer
                    props={null} type="weekly"
                    isLoading={loading}
                    allMarkers={weeklyRentContent?.allMarkers}
                    additionalFilter={additionalFilter}
                    setAdditionalFilter={setAdditionalFilter}
                    nameCriteria={filterNameValue}
                    setNameCriteria={setFilterNameValue}
                />
            </>
        )
    }else{
        return(
            <>
                <ArticlesContainer
                    props={!weeklyRentContent || weeklyRentContent.edges.length === 0 ? null : weeklyRentContent?.edges}
                    type="weekly"
                    isLoading={loading}
                    allMarkers={weeklyRentContent?.allMarkers}
                    additionalFilter={additionalFilter}
                    setAdditionalFilter={setAdditionalFilter}
                    nameCriteria={filterNameValue}
                    setNameCriteria={setFilterNameValue}
                />
                {
                displayable&&
                    <ArticlesPagination
                    totalCount={weeklyRentContent.totalCount}
                    pageInfo = {weeklyRentContent.pageInfo}
                />
                }
            </>
        )
    }
};

//SEASONAL
const initialSearchSeasonalArticleData = new SearchAccomodationArticles("Seasonal");
export const SeasonalLocation:FunctionComponent = ()=>{
    const location = useLocation();
    const intl = useIntl();
    const [searchParams,setSearchParams] = useSearchParams();
    const {searchData,dispatchSearchData} = useContext(FilterModuleContext);
    const [seasonalRentContent,setWeeklyRentContent] = useState<any|null>(null)
    const [displayable,isDisplayable] = useState<boolean>(false)
    const [articleSearchData,dispatchArticleSearchData] = useReducer<(articleSearchData: SearchAccomodationArticles, action: any) => any>(articlesPaginationReducer,initialSearchSeasonalArticleData)
    const [additionalFilter, setAdditionalFilter] = useState<string[]>([]);
    const [filterNameValue,setFilterNameValue] = useState<string | undefined>(undefined);

    const { error, loading, refetch } = useQuery(SEASONAL_RENT, {
        variables:{
            first:articleSearchData.first,
            after:articleSearchData.after,
            last:articleSearchData.last,
            before:articleSearchData.before,
            roomsRange:articleSearchData.qSeasonal.roomsRange,
            price:articleSearchData.qSeasonal.price,
            objectTypes:articleSearchData.qSeasonal.objectTypes,
            parking: articleSearchData.qRent.parking? true : additionalFilter.includes("parking")? true : null  ,
            wifi: articleSearchData.qRent.wifi? true : additionalFilter.includes("wifi")? true : null  ,
            tv: articleSearchData.qRent.tv? true : additionalFilter.includes("tv")? true : null  ,
            pet: articleSearchData.qRent.pet? true : additionalFilter.includes("pet")? true : null  ,
            fireplace: articleSearchData.qRent.fireplace? true : additionalFilter.includes("fireplace")? true : null  ,
            dishwasher: articleSearchData.qRent.dishwasher? true : additionalFilter.includes("dishwasher")? true : null  ,
            name: searchParams.get("name")
        },
        ssr: true,
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted:(data)=>{
            setWeeklyRentContent(data.search)
            isDisplayable(true)
        }
    });

    useEffect(()=>{
        if(!!location.state){
            const data = location.state
            const paramArrays = generateSearchArticleParamData(data)
            setSearchParams(generateSearchArticleURL(paramArrays))
        }else{
            dispatchSearchData({
                type: 'InitTypeOfSearch',
                value: "seasonal",
            });
        }
    },[]);

    useEffect(() => {
        const paramArrays = generateSearchArticleParamData(searchData)
        setSearchParams(generateSearchArticleURL(paramArrays))        
    },[additionalFilter, filterNameValue]);

    useEffect(()=>{
        dispatchArticleSearchData({
            type: "seasonal",
            direction:searchParams.get("dir"),
            id: searchParams.get("page"),
            roomsRange:searchParams.get("roomsRange"),
            minGuests:searchParams.get("minGuests"),
            price:searchParams.get("price"),
            objectTypes:searchParams.get("objectTypes"),
            parking:searchParams.get("parking"),
            wifi:searchParams.get("wifi"),
            tv:searchParams.get("tv"),
            pet:searchParams.get("pet"),
            fireplace:searchParams.get("fireplace"),
            dishwasher:searchParams.get("dishwasher"),        
        });
    },[searchParams]);

    useEffect(()=>{
        refetch()
    },[articleSearchData]);

    if(error){
        return(
            <div className="articles-header empty" style={{backgroundColor:"var(--light-grey)",display:"flex"}}>
                <h4>
                    {intl.formatMessage({id:"articles-no-accomodations-correspond", defaultMessage: "Aucun bien ne correspond aux filtres de recherche."})}
                </h4>
                <p>
                    {intl.formatMessage({id:"articles-modify-your-filters", defaultMessage: "Nous n'avons pas de bien immobilier à vous présenter dans cette catégorie. Merci de nous contacter pour toute demandes particulières."})}
                </p>
            </div>
        )
    }else{
        return(
            <>
                <ArticlesContainer
                    props={!seasonalRentContent || seasonalRentContent.edges.length === 0 ? null : seasonalRentContent?.edges}
                    type="seasonal"
                    isLoading={loading}
                    allMarkers={seasonalRentContent?.allMarkers}
                    additionalFilter={additionalFilter}
                    setAdditionalFilter={setAdditionalFilter}
                    nameCriteria={filterNameValue}
                    setNameCriteria={setFilterNameValue}
                />
                {displayable&&
                    <ArticlesPagination
                        totalCount={seasonalRentContent.totalCount}
                        pageInfo = {seasonalRentContent.pageInfo}
                    />
                }
            </>
        )
    }
};

//ANNUAL
const initialSearchAnnualArticleData = new SearchAccomodationArticles("Annual");
export const AnnualLocation:FunctionComponent = ()=>{
    const location = useLocation();
    const intl = useIntl();
    const [searchParams,setSearchParams] = useSearchParams();
    const {searchData,dispatchSearchData} = useContext(FilterModuleContext);
    const [annualRentContent,setWeeklyRentContent] = useState<any|null>(null)
    const [displayable,isDisplayable] = useState<boolean>(false)
    const [articleSearchData,dispatchArticleSearchData] = useReducer<(articleSearchData: SearchAccomodationArticles, action: any) => any>(articlesPaginationReducer,initialSearchAnnualArticleData)
    const [additionalFilter, setAdditionalFilter] = useState<string[]>([]);
    const [filterNameValue,setFilterNameValue] = useState<string | undefined>(undefined);

    const { error, loading, refetch } = useQuery(ANNUAL_RENT, {
        variables:{
            first:articleSearchData.first,
            after:articleSearchData.after,
            last:articleSearchData.last,
            before:articleSearchData.before,
            roomsRange:articleSearchData.qAnnual.roomsRange,
            surface:articleSearchData.qAnnual.minSurface,
            price:articleSearchData.qAnnual.price,
            objectTypes:articleSearchData.qAnnual.objectTypes,
            parking: articleSearchData.qRent.parking? true : additionalFilter.includes("parking")? true : null  ,
            wifi: articleSearchData.qRent.wifi? true : additionalFilter.includes("wifi")? true : null  ,
            tv: articleSearchData.qRent.tv? true : additionalFilter.includes("tv")? true : null  ,
            pet: articleSearchData.qRent.pet? true : additionalFilter.includes("pet")? true : null  ,
            fireplace: articleSearchData.qRent.fireplace? true : additionalFilter.includes("fireplace")? true : null  ,
            dishwasher: articleSearchData.qRent.dishwasher? true : additionalFilter.includes("dishwasher")? true : null  ,
            name: searchParams.get("name")
        },
        ssr: true,
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted:(data)=>{
            setWeeklyRentContent(data.search)
            isDisplayable(true)
        }
    });

    useEffect(()=>{
        if(!!location.state){
            const data = location.state
            const paramArrays = generateSearchArticleParamData(data)
            setSearchParams(generateSearchArticleURL(paramArrays))
        }else{
            dispatchSearchData({
                type: 'InitTypeOfSearch',
                value: "annual",
            });
        }
    },[]);

    useEffect(() => {
        const paramArrays = generateSearchArticleParamData(searchData)
        setSearchParams(generateSearchArticleURL(paramArrays))        
    },[additionalFilter,filterNameValue]);

    useEffect(()=>{
        dispatchArticleSearchData({
            type: "annual",
            direction:searchParams.get("dir"),
            id: searchParams.get("page"),
            roomsRange:searchParams.get("roomsRange"),
            price:searchParams.get("price"),
            minSurface:searchParams.get("minSurface"),
            objectTypes:searchParams.get("objectTypes"),
            parking:searchParams.get("parking"),
            wifi:searchParams.get("wifi"),
            tv:searchParams.get("tv"),
            pet:searchParams.get("pet"),
            fireplace:searchParams.get("fireplace"),
            dishwasher:searchParams.get("dishwasher"),
        });
    },[searchParams])

    useEffect(()=>{
        refetch()
    },[articleSearchData])

    if(error){
        return(
            <div className="articles-header empty" style={{backgroundColor:"var(--light-grey)",display:"flex"}}>
                <h4>
                    {intl.formatMessage({id:"articles-no-accomodations-correspond", defaultMessage: "Aucun bien ne correspond aux filtres de recherche."})}
                </h4>
                <p>
                    {intl.formatMessage({id:"articles-modify-your-filters", defaultMessage: "Nous n'avons pas de bien immobilier à vous présenter dans cette catégorie. Merci de nous contacter pour toute demandes particulières."})}
                </p>
            </div>
        )
    }else{
        return(
            <>
                <ArticlesContainer
                    props={!annualRentContent || annualRentContent.edges.length === 0 ? null : annualRentContent?.edges}
                    type="annual"
                    isLoading={loading}
                    allMarkers={annualRentContent?.allMarkers}
                    additionalFilter={additionalFilter}
                    setAdditionalFilter={setAdditionalFilter}
                    nameCriteria={filterNameValue}
                    setNameCriteria={setFilterNameValue}
                />
                {
                displayable&&
                    <ArticlesPagination
                        totalCount={annualRentContent.totalCount}
                        pageInfo = {annualRentContent.pageInfo}
                    />
                }
            </>
        )
    }
};
