import { gql, useQuery } from "@apollo/client";
import React, { FunctionComponent, useContext, useEffect, useReducer, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { IMAGE_FIELDS, SALE_FEATURES_FIELDS } from "@shared/fragments";
import { SearchAccomodationArticles } from "../../models/searchArticles";
import { ArticlesContainer, ArticlesPagination } from "./articles";
import articlesPaginationReducer from "./articlesPagination.reducer";
import { generateSearchArticleParamData, generateSearchArticleURL } from "../../utils/search";
import { FilterModuleContext } from "../../contexts/filterModule";
import { useIntl } from "react-intl";
import "./articles.scss";

const ARTICLE_FOR_SALE = gql`
    ${IMAGE_FIELDS}
    ${SALE_FEATURES_FIELDS}
    query GetSalesSummaries(
        $first:Int,
        $after:String,
        $last:Int,
        $before:String,
        $roomsRange:IntRangeFilterInput,
        $surface:Int,
        $price:PriceRangeFilterInput,
        $objectTypes:[String!],
        $name:String
        )
        {
            search(
                where:{
                    saleAccommodation:{
                        roomsRange:$roomsRange,
                        price:$price,
                        surface:$surface,
                        objectTypes:$objectTypes,
                        name:$name
                    }
                },
                first:$first,
                after:$after,
                last:$last,
                before:$before
                paginationLinksLength : 3
            )
            {
                totalCount
                edges {
                    cursor
                    node {
                        ... on SaleAccommodationSummary {
                            __typename,
                            id,
                            title {value},
                            coordinates,
                            priceOnDemand,
                            objectType,
                            novelty
                            promoted
                            price {currency , amount},
                            images { ...ImageFields },
                            features { ...SaleFeaturesFields }
                        }
                    }
                }
                pageInfo{
                    page
                    pageSize
                    pageCount
                    hasPreviousPage
                    previousCursor
                    hasNextPage
                    nextCursor
                    lastCursor
                    linkCursors { page cursor }
                }
                allMarkers { id coordinates }
            }
        }
`;
const initialSearchArticleData = new SearchAccomodationArticles("Sale");

const Sale:FunctionComponent = ()=>{
    const location = useLocation();
    const intl = useIntl();
    const [searchParams,setSearchParams] = useSearchParams();
    const {searchData,dispatchSearchData} = useContext(FilterModuleContext);
    const [saleContent,setSaleContent] = useState<any|null>(null);
    const [displayable,isDisplayable] = useState<boolean>(false);
    const [articleSearchData,dispatchArticleSearchData] = useReducer<(articleSearchData: SearchAccomodationArticles, action: any) => any>(articlesPaginationReducer,initialSearchArticleData);
    const [filterNameValue,setFilterNameValue] = useState<string | undefined>(undefined);

    useEffect(()=>{//STEP 1 : Create search url if state is present
        if(!!location.state){
            const data = location.state
            const paramArrays = generateSearchArticleParamData(data)
            setSearchParams(generateSearchArticleURL(paramArrays))
        }else{
            dispatchSearchData({
                type: 'InitTypeOfSearch',
                value: "sale",
            });
        }
    },[]);

    useEffect(() => {
        const paramArrays = generateSearchArticleParamData(searchData);
        setSearchParams(generateSearchArticleURL(paramArrays));        
    },[filterNameValue]);

    useEffect(()=>{ //STEP 2: Dispatch search data changes as soon as URL changes
        dispatchArticleSearchData({
            type: "sale",
            direction:searchParams.get("dir"),
            id: searchParams.get("page"),
            roomsRange:searchParams.get("roomsRange"),
            price:searchParams.get("price"),
            minSurface:searchParams.get("minSurface"),
            objectTypes:searchParams.get("objectTypes"),
        });
    },[searchParams])

    const { error, loading, refetch } = useQuery(ARTICLE_FOR_SALE, { //STEP 3 : Data fetching
        variables:{
            first:articleSearchData.first,
            after:articleSearchData.after,
            last:articleSearchData.last,
            before:articleSearchData.before,
            roomsRange:articleSearchData.qSale.roomsRange,
            surface:articleSearchData.qSale.minSurface,
            price:articleSearchData.qSale.price,
            objectTypes:articleSearchData.qSale.objectTypes,
            name: searchParams.get("name")
        },
        ssr: true,
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted:(data)=>{
            setSaleContent(data.search)
            isDisplayable(true)
        }
    });

    useEffect(()=>{ //STEP 4: Refetch datas as soon as query change
        refetch()
    },[articleSearchData])

    if(error){
        return(
            <div className="articles-header empty" style={{backgroundColor:"var(--light-grey)",display:"flex"}}>
                <h4>
                    {intl.formatMessage({id:"articles-no-accomodations-correspond", defaultMessage: "Aucun bien ne correspond aux filtres de recherche."})}
                </h4>
                <p>
                    {intl.formatMessage({id:"articles-modify-your-filters", defaultMessage: "Nous n'avons pas de bien immobilier à vous présenter dans cette catégorie. Merci de nous contacter pour toute demandes particulières."})}
                </p>
            </div>
        )
    }else{
        return (
        <>
            <ArticlesContainer
                props={!saleContent || saleContent.edges.length === 0 ? null : saleContent?.edges}
                type="sale"
                isLoading={loading}
                allMarkers={saleContent?.allMarkers}
                nameCriteria={filterNameValue}
                setNameCriteria={setFilterNameValue}
            />
            {displayable &&
                <ArticlesPagination
                    totalCount={saleContent.totalCount}
                    pageInfo = {saleContent.pageInfo}
                />
            }
        </>

        )
    }
}

export default Sale

